import React from "react"
import BackgroundImage from "gatsby-background-image"
import "../css/background-image.css"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { convertToBgImage } from "gbimage-bridge"
import { getImage } from "gatsby-plugin-image"
import { graphql, useStaticQuery } from "gatsby"

const NotFoundPage = () => {
  const { placeholderImage } = useStaticQuery(
    graphql`
      query {
        placeholderImage: file(relativePath: { eq: "404_view.jpeg" }) {
          childImageSharp {
            gatsbyImageData(
              width: 1280
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    `
  )

  const image = getImage(placeholderImage)
  const bgImage = convertToBgImage(image)

  return (
    <Layout>
      <SEO title="404: No encontrada" />
      <BackgroundImage
        Tag="section"
        {...bgImage}
        preserveStackingContext
        style={{ position: "fixed", top: 0 }}
        className="backimage"
      >
      </BackgroundImage>
    </Layout>
  )
}
export default NotFoundPage
